import { API } from "aws-amplify";
import dayjs from "dayjs";
import { constructOrganisationUrl } from "utils/actions";

export const GET_UNIT_DETAILS_REQUEST = "@@unit/GET_UNIT_DETAILS_REQUEST";
export const GET_UNIT_DETAILS_SUCCESS = "@@unit/GET_UNIT_DETAILS_SUCCESS";
export const GET_UNIT_DETAILS_FAILURE = "@@unit/GET_UNIT_DETAILS_FAILURE";

export function getUnitDetails(id) {
  return async (dispatch) => {
    dispatch({
      type: GET_UNIT_DETAILS_REQUEST,
    });
    try {
      let url = `/unit/${id}`;
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_UNIT_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIT_DETAILS_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_UNIT_LIST_REQUEST = "@@unit/GET_UNIT_LIST_REQUEST";
export const GET_UNIT_LIST_SUCCESS = "@@unit/GET_UNIT_LIST_SUCCESS";
export const GET_UNIT_LIST_FAILURE = "@@unit/GET_UNIT_LIST_FAILURE";

export function getUnitList() {
  return async (dispatch, getState) => {
    dispatch({
      type: GET_UNIT_LIST_REQUEST,
    });
    try {
      const { profile } = getState();
      const { organisation } = profile;
      let url = "/unit";
      const response = await API.get(
        "cmsAPI",
        constructOrganisationUrl(url, organisation)
      );
      dispatch({
        type: GET_UNIT_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIT_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_UNIT_STATUS_REQUEST = "@@unit/GET_UNIT_STATUS_REQUEST";
export const GET_UNIT_STATUS_SUCCESS = "@@unit/GET_UNIT_STATUS_SUCCESS";
export const GET_UNIT_STATUS_FAILURE = "@@unit/GET_UNIT_STATUS_FAILURE";

export function getUnitStatus(id) {
  return async (dispatch) => {
    dispatch({
      type: GET_UNIT_STATUS_REQUEST,
    });
    try {
      let url = `/status?unit=${id}`;
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_UNIT_STATUS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIT_STATUS_FAILURE,
        payload: error,
      });
    }
  };
}

export const CLEAR_UNIT_STATUS = "@@unit/CLEAR_UNIT_STATUS";

export function clearUnitStatus() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_UNIT_STATUS,
    });
  };
}

export const CLEAR_UNIT_DEBUG = "@@unit/CLEAR_UNIT_DEBUG";

export function clearUnitDebug() {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_UNIT_DEBUG,
    });
  };
}

export const GET_UNIT_EVENTS_LIST_REQUEST =
  "@@unit/GET_UNIT_EVENTS_LIST_REQUEST";
export const GET_UNIT_EVENTS_LIST_SUCCESS =
  "@@unit/GET_UNIT_EVENTS_LIST_SUCCESS";
export const GET_UNIT_EVENTS_LIST_FAILURE =
  "@@unit/GET_UNIT_EVENTS_LIST_FAILURE";

export function getUnitEventsList(unit, site) {
  return async (dispatch) => {
    if (!(unit && site)) return;
    dispatch({
      type: GET_UNIT_EVENTS_LIST_REQUEST,
    });
    try {
      let url = `/event?unit=${unit}&site=${site}`;
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_UNIT_EVENTS_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIT_EVENTS_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_UNIT_PICTURE_DETAILS_SUCCESS =
  "@@unit/GET_UNIT_PICTURE_DETAILS_SUCCESS";

export function getUnitPictureDetails(id) {
  return async (dispatch) => {
    try {
      let url = `/status?unit=${id}`;
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_UNIT_PICTURE_DETAILS_SUCCESS,
        payload: response,
      });
    } catch (_error) {
      // console.log(error);
    }
  };
}

export const GET_UNIT_DEBUG_REQUEST = "@@unit/GET_UNIT_DEBUG_REQUEST";
export const GET_UNIT_DEBUG_SUCCESS = "@@unit/GET_UNIT_DEBUG_SUCCESS";
export const GET_UNIT_DEBUG_FAILURE = "@@unit/GET_UNIT_DEBUG_FAILURE";

export function getUnitDebug(id) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: GET_UNIT_DEBUG_REQUEST,
    });
    try {
      let url = `/unit/${id}/live_data`;
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_UNIT_DEBUG_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIT_DEBUG_FAILURE,
        payload: error,
      });
    }
  };
}

export const UNIT_COMMAND_REQUEST = "@@unit/UNIT_COMMAND_REQUEST";
export const UNIT_COMMAND_SUCCESS = "@@unit/UNIT_COMMAND_SUCCESS";
export const UNIT_COMMAND_FAILURE = "@@unit/UNIT_COMMAND_FAILURE";

export function unitCommand(formData, source = "debug", callback = undefined) {
  return async (dispatch) => {
    dispatch({
      type: UNIT_COMMAND_REQUEST,
    });
    try {
      const init = {
        body: formData,
      };
      const response = await API.post("cmsAPI", `/command`, init);

      dispatch({
        type: UNIT_COMMAND_SUCCESS,
        payload: response,
      });

      if (callback) {
        callback();
      }
    } catch (error) {
      dispatch({
        type: UNIT_COMMAND_FAILURE,
        payload: { error, source },
      });
    }
  };
}

export const GET_UNIT_LOGS_REQUEST = "@@unit/GET_UNIT_LOGS_REQUEST";
export const GET_UNIT_LOGS_SUCCESS = "@@unit/GET_UNIT_LOGS_SUCCESS";
export const GET_UNIT_LOGS_FAILURE = "@@unit/GET_UNIT_LOGS_FAILURE";

export function getUnitLogs(id) {
  return async (dispatch) => {
    if (!id) return;
    dispatch({
      type: GET_UNIT_LOGS_REQUEST,
    });
    try {
      let url = `/unit/${id}/live_data`;
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_UNIT_LOGS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIT_LOGS_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_UNIT_OPERATION_LIST_REQUEST =
  "@@unit/GET_UNIT_OPERATION_LIST_REQUEST";
export const GET_UNIT_OPERATION_LIST_SUCCESS =
  "@@unit/GET_UNIT_OPERATION_LIST_SUCCESS";
export const GET_UNIT_OPERATION_LIST_FAILURE =
  "@@unit/GET_UNIT_OPERATION_LIST_FAILURE";

export function getUnitOperation(id, exclude_ended) {
  return async (dispatch) => {
    dispatch({
      type: GET_UNIT_OPERATION_LIST_REQUEST,
    });
    try {
      let url = `/unit/${id}/operation?exclude_ended=${exclude_ended}`;
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_UNIT_OPERATION_LIST_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIT_OPERATION_LIST_FAILURE,
        payload: error,
      });
    }
  };
}

export const POST_UNIT_OPERATION_REQUEST = "@@unit/POST_UNIT_OPERATION_REQUEST";
export const POST_UNIT_OPERATION_SUCCESS = "@@unit/POST_UNIT_OPERATION_SUCCESS";
export const POST_UNIT_OPERATION_FAILURE = "@@unit/POST_UNIT_OPERATION_FAILURE";

export function createUnitOperation(id, formData, showRecentEnded) {
  return async (dispatch) => {
    dispatch({
      type: POST_UNIT_OPERATION_REQUEST,
    });
    try {
      const init = {
        body: formData,
      };

      let url = `/unit/${id}/operation`;
      const response = await API.post("cmsAPI", url, init);
      dispatch({
        type: POST_UNIT_OPERATION_SUCCESS,
        payload: response,
      });

      dispatch(getUnitOperation(id, !showRecentEnded));

      return response;
    } catch (error) {
      dispatch({
        type: POST_UNIT_OPERATION_FAILURE,
        payload: error,
      });
    }
  };
}

export const DELETE_UNIT_OPERATION_REQUEST =
  "@@unit/DELETE_UNIT_OPERATION_REQUEST";
export const DELETE_UNIT_OPERATION_SUCCESS =
  "@@unit/DELETE_UNIT_OPERATION_SUCCESS";
export const DELETE_UNIT_OPERATION_FAILURE =
  "@@unit/DELETE_UNIT_OPERATION_FAILURE";

export function deleteUnitOperation(id, operationId, showRecentEnded) {
  return async (dispatch) => {
    dispatch({
      type: DELETE_UNIT_OPERATION_REQUEST,
    });
    try {
      let url = `/unit/${id}/operation/${operationId}`;
      const response = await API.del("cmsAPI", url);
      dispatch({
        type: DELETE_UNIT_OPERATION_SUCCESS,
        payload: response,
      });

      dispatch(getUnitOperation(id, !showRecentEnded));

      return response;
    } catch (error) {
      dispatch({
        type: DELETE_UNIT_OPERATION_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_UNIT_DETAILS_MORE_REQUEST =
  "@@unit/GET_UNIT_DETAILS_MORE_REQUEST";
export const GET_UNIT_DETAILS_MORE_SUCCESS =
  "@@unit/GET_UNIT_DETAILS_MORE_SUCCESS";
export const GET_UNIT_DETAILS_MORE_FAILURE =
  "@@unit/GET_UNIT_DETAILS_MORE_FAILURE";

export function getUnitDetailsMore(id) {
  return async (dispatch) => {
    dispatch({
      type: GET_UNIT_DETAILS_MORE_REQUEST,
    });
    try {
      let url = `/unit/${id}/details`;
      const response = await API.get("cmsAPI", url);
      dispatch({
        type: GET_UNIT_DETAILS_MORE_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIT_DETAILS_MORE_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_UNIT_ENERGY_USAGE_SUMMARY_REQUEST =
  "@@unit/GET_UNIT_ENERGY_USAGE_SUMMARY_REQUEST";
export const GET_UNIT_ENERGY_USAGE_SUMMARY_SUCCESS =
  "@@unit/GET_UNIT_ENERGY_USAGE_SUMMARY_SUCCESS";
export const GET_UNIT_ENERGY_USAGE_SUMMARY_FAILURE =
  "@@unit/GET_UNIT_ENERGY_USAGE_SUMMARY_FAILURE";

export function getEnergyUsageSummary(siteId, window, dateRange) {
  return async (dispatch) => {
    if (!siteId) return;
    if (!dayjs(dateRange.start).isValid()) return;
    dispatch({
      type: GET_UNIT_ENERGY_USAGE_SUMMARY_REQUEST,
    });
    try {
      let qParams = `?window=${window}`;
      if (dateRange.start) {
        qParams = `${qParams}&start=${dateRange.start}`;
      }
      if (dateRange.end) {
        qParams = `${qParams}&end=${dateRange.end}`;
      }

      const response = await API.get(
        "cmsAPI",
        `/site/${siteId}/energy-usage-summary${qParams}`
      );

      dispatch({
        type: GET_UNIT_ENERGY_USAGE_SUMMARY_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIT_ENERGY_USAGE_SUMMARY_FAILURE,
        payload: error,
      });
    }
  };
}

export const GET_UNIT_ENERGY_USAGE_HISTORY_REQUEST =
  "@@unit/GET_UNIT_ENERGY_USAGE_HISTORY_REQUEST";
export const GET_UNIT_ENERGY_USAGE_HISTORY_SUCCESS =
  "@@unit/GET_UNIT_ENERGY_USAGE_HISTORY_SUCCESS";
export const GET_UNIT_ENERGY_USAGE_HISTORY_FAILURE =
  "@@unit/GET_UNIT_ENERGY_USAGE_HISTORY_FAILURE";

export function getEnergyUsageHistory(siteId, window, dateRange) {
  return async (dispatch) => {
    if (!siteId) return;
    if (!dayjs(dateRange.start).isValid()) return;
    dispatch({
      type: GET_UNIT_ENERGY_USAGE_HISTORY_REQUEST,
    });
    try {
      let qParams = `?window=${window}`;
      if (dateRange.start) {
        qParams = `${qParams}&start=${dateRange.start}`;
      }
      if (dateRange.end) {
        qParams = `${qParams}&end=${dateRange.end}`;
      }

      const response = await API.get(
        "cmsAPI",
        `/site/${siteId}/energy-usage-history${qParams}`
      );

      dispatch({
        type: GET_UNIT_ENERGY_USAGE_HISTORY_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: GET_UNIT_ENERGY_USAGE_HISTORY_FAILURE,
        payload: error,
      });
    }
  };
}
